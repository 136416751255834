<template>
  <div class="autocomplete">

        <div class="form-group input-group">
            <input type="search"
            :placeholder="placeHolderText"
            v-model="keywordSearch"
            class="form-control"
            :class="{ 'loading-circle' : (keywordSearch.length > 2), 'hide-loading-circle': resultItems.length > 0 || resultItems.length == 0 && !autoCompleteProgress  }"
            @keyup="!autoCompleteProgress ? onKeyUp(keywordSearch) : ''"
            @search="!autoCompleteProgress ? onKeyUp(keywordSearch) : ''"
           />
        </div>
        <ul class="autocomplete-results list-group" v-if="resultItems.length > 0">
            <li class="list-group-item" v-for="(item,i) in resultItems" :key="i" @click="keywordSearch='';onSelected(item[itemId], item[itemText])">
            {{ item[itemText] }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'AutoComplete',
  inheritAttrs: false,
  props: {
    placeHolderText: String,
    onKeyUp: Function,
    onSelected: Function,
    resultItems: Array,
    autoCompleteProgress: Boolean,
    itemText: String,
    itemId: String
  },

  data () {
    return {
      keywordSearch: ''
    }
  }
}
</script>

<style lang="scss">
.autocomplete{
    position:relative;

    .autocomplete-results{
        position:absolute;
        top:100%;
        width:100%;
        left:0;
        li {
            cursor:pointer;

            &:first-child,
            &:last-child{
                border-radius:0;
            }
        }
    }
}
</style>
